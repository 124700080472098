@import "defaults";
@import "../../mixins/gradients";

.select2-container--classic {
  @import "single";
  @import "multiple";

  .select2-search--dropdown {
    .select2-search__field {
      border: 1px solid $border-color;
      outline: 0;
    }
  }

  .select2-search--inline {
    .select2-search__field {
      outline: 0;
      box-shadow: none;
    }
  }

  .select2-dropdown {
    background-color: $dropdown-bg-color;
    border: 1px solid transparent;
  }

  .select2-dropdown--above {
    border-bottom: none;
  }

  .select2-dropdown--below {
    border-top: none;
  }

  .select2-results > .select2-results__options {
    max-height: $results-max-height;
    overflow-y: auto;
  }

  .select2-results__option {
    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: $results-choice-fg-unselectable-color;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $results-choice-bg-hover-color;
    color: $results-choice-fg-hover-color;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  &.select2-container--open .select2-dropdown {
    border-color: $focus-border-color;
  }
}
