
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
@import "node_modules/font-awesome/scss/font-awesome";

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Select2
@import "node_modules/select2/src/scss/core";

.dataTables_filter {
    text-align: right;
}
.dataTables_filter input {
    margin-left: 5px;
}